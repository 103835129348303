import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/auth';

export default class {

    constructor(api) {
        this.urlApi = api;
    }

    async headers() {
        return { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + await firebase.auth().currentUser.getIdToken(),
        };
    }

    async get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {
        console.log("chamou post",typeof endpoint,endpoint,data)
        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        console.log(this.urlApi + endpoint)
        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

}